import React from 'react';
import Img1 from './assets/images/event1.jpg';
import Img2 from './assets/images/event2.jpg';
import Img3 from './assets/images/event3.jpg';

function Events() {
  // Example data for events
  const events = [
    {
      title: 'Stanford Gen AI Guest Speaker: Vikash Rungta',
      date: 'TBA',
      description: 'Join us with Vikash Rungta, Founder of Rise2 AI Labs. With over 20 years of experience in AI innovation, he has built generative models like Llama while prioritizing privacy and security. Vikash also teaches AI for Product Managers at Stanford Continuing Studies, blending technology, policy, and ethics.',
      image: Img1, // Use imported image
    },
    {
      title: 'AlwaysAI Guest Speaker: Olaf Zielinski',
      date: 'March 4, 2025 at 12:00PM @ Markstein 304',
      description: 'Join us for a special guest talk by Olaf Zielinski, a Software Engineer at AlwaysAI. This is an excellent opportunity to learn about his journey into AI, discover career tips, and network with professionals in the field.',
      image: Img2,
    },
    {
      title: ' Interview Prep Workshop',
      date: 'February 27, 2025 at 12:00PM @ ACD 206',
      description: 'Join us for the Interview Prep Workshop hosted by the CSUSM AI Club! We’ll be covering LeetCode practice and AI-related resources to help you get ready for your next interview.',
      image: Img3,
    },    
  ];

  return (
    <div className="my-8 px-4 lg:px-16">
      <h2 className="text-3xl text-white font-bold mb-6">Events</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {events.map((event, index) => (
          <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={event.image}
              alt={event.title}
              className="w-full h-40 object-cover"
            />
            <div className="p-4">
              <h3 className="text-xl font-semibold mb-2">{event.title}</h3>
              <p className="text-gray-600 mb-2">{event.date}</p>
              <p className="text-gray-700 mb-4">{event.description}</p>
              {/* <button className="px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-700 hover:font-bold">
                Learn More
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Events;
