import React from "react";
import videoSource from "./assets/images/banner.mp4"; // Adjust the path as needed

function Banner() {
  return (
    <div className="relative text-center py-16">
      {/* Video Background */}
      <video
        autoPlay
        loop
        muted
        playsInline
        disablePictureInPicture // Add this attribute to disable PiP
        controlsList="nodownload"
        className="absolute inset-0 w-full h-full object-cover"
      >
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay for better text visibility */}
      <div className="absolute inset-0 bg-black bg-opacity-35"></div>

      {/* Content */}
      <div className="relative z-10">
        <h1 className="text-4xl font-bold mb-4 text-white max-w-xl mx-auto">
        Think AI, Create the Future.
        </h1>
        <p className="mb-6 text-lg max-w-xl mx-auto text-white">
        Welcome to the AI Club at CSUSM – where innovation thrives, collaboration drives growth, and technology shapes the future. 
        Join us as we learn, create, and lead in the fields of Artificial Intelligence and Machine Learning!
        </p>
        <a 
  href="https://forms.gle/ve1mwXHtY7QuiUg38" 
  target="_blank" 
  rel="noopener noreferrer"
  className="px-6 py-3 bg-blue-500 text-gray-900 rounded-lg bg-opacity-70 hover:text-white hover:bg-blue-500 hover:font-bold"
>
  Sign Up Now
</a>
      </div>
    </div>
  );
}

export default Banner;
